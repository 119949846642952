<template>
  <Dialog
    v-model:visible="displayModal"
    :modal="true"
    :closeOnEscape="true"
    :dismissableMask="true"
    :breakpoints="{'1300px': '90vw'}"
    :style="{width: '90vw'}"
  >
    <template #header>
      <div style="color:#1c3faa;">
        <h4>
          <i
            class="pi pi-file"
            style="font-size: 1.5rem;margin-right:10px"></i>
          <strong style="font-size:20px">Información - {{ 'Entrada de Mercancía' }}</strong>
        </h4>
      </div>
    </template>
    <div>
      <div class="w-full text-xxs">
        <!--Cabecera entrada-->
        <div class="grid grid-cols-1 xl:grid-cols-2 lg:gap-x-10 xl:gap-x-60">
          <div class="grid grid-rows-4 gap-1 pt-3">
            <div class="grid grid-cols-4 items-center">
              <div class="col-span-1">
                <strong>Código Cliente/Entidad: </strong>
              </div>
              <div class="col-span-3">
                <InputText
                  class="p-inputtext-xxs inputsHeader text-xxs"
                  v-model="dataEntradaMercancia.CardCode"
                  disabled/>
              </div>
            </div>
            <div class="grid grid-cols-4 items-center">
              <div class="col-span-1">
                <strong>Nombre Cliente/Entidad: </strong>
              </div>
              <div class="col-span-3">
                <InputText
                  class="p-inputtext-xxs inputsHeader text-xxs"
                  v-model="dataEntradaMercancia.CardName"
                  disabled/>
              </div>
            </div>
            <div class="grid grid-cols-4 items-center">
              <div class="col-span-1">
                <strong>N. Factura: </strong>
              </div>
              <div class="col-span-3">
                <InputText
                  class="p-inputtext-xxs inputsHeader text-xxs"
                  v-model="dataEntradaMercancia.NumAtCard"
                  disabled/>
              </div>
            </div>
            <div class="grid grid-cols-4 items-center">
              <div class="col-span-1">
                <strong>N. Entrada: </strong>
              </div>
              <div class="col-span-3">
                <InputText
                  class="p-inputtext-xxs inputsHeader text-xxs"
                  v-model="dataEntradaMercancia.DocNum"
                  disabled/>
              </div>
            </div>
          </div>
          <div class="grid grid-rows-4 gap-1 pt-1">
            <div class="grid grid-cols-4 items-center">
              <div class="xl:col-end-4 col-span-1">
                <strong>Fecha de Contabilización: </strong>
              </div>
              <div class="xl:col-end-5 col-span-3 xl:col-span-1">
                <InputText
                  class="p-inputtext-xxs inputsHeader text-xxs"
                  v-model="dataEntradaMercancia.TaxDate"
                  disabled/>
              </div>
            </div>
            <div class="grid grid-cols-4 items-center">
              <div class="xl:col-end-4 col-span-1">
                <strong>Fecha de documento: </strong>
              </div>
              <div class="xl:col-end-5 col-span-3 xl:col-span-1">
                <InputText
                  class="p-inputtext-xxs inputsHeader text-xxs"
                  v-model="dataEntradaMercancia.DocDate"
                  disabled/>
              </div>
            </div>
            <div class="grid grid-cols-4 items-center">
              <div class="xl:col-end-4 col-span-1">
                <strong>Fecha de vencimiento: </strong>
              </div>
              <div class="xl:col-end-5 col-span-3 xl:col-span-1">
                <InputText
                  class="p-inputtext-xxs inputsHeader text-xxs"
                  v-model="dataEntradaMercancia.DocDueDate"
                  disabled/>
              </div>
            </div>
            <div class="grid grid-cols-4 items-center">
              <div class="xl:col-end-4 col-span-1">
                <strong>Estado: </strong>
              </div>
              <div class="xl:col-end-5 col-span-3 xl:col-span-1">
                <InputText
                  class="p-inputtext-xxs inputsHeader text-xxs"
                  v-model="dataEntradaMercancia.estadoNombre"
                  disabled/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Detalle entrada -->
      <div
        style="width: 100%"
        class="mt-6">
        <div>
          <DataTable
            :value="dataEntradaMercancia.detalle"
            class="p-datatable-sm text-xxs"
            showGridlines
            dataKey="id"
            responsiveLayout="scroll"
            scrollHeight="500px"
          >
            <template #empty>
              No hay datos para mostrar
            </template>
            <Column
              field="LineNum"
              header="Indice"
              headerStyle="justify-content: center;"
              style="min-width:4rem"
              bodyStyle="text-align: center">
              <template #body="{data}">
                <div>{{ data.LineNum }}</div>
              </template>
            </Column>
            <Column
              field="ItemCode"
              header="Artículo"
              headerStyle="justify-content: center;"
              style="min-width:30rem"
              bodyStyle="text-align: center">
              <template #body="{data}">
                <div class="grid grid-cols-1 w-full">
                  <div class="flex justify-between items-center my-1">
                    <div>
                      <strong>Código Mx: </strong>{{ data.ItemCode }}
                    </div>
                    <div class="flex gap-2">
                      <Badge
                        v-if="data.ReqMarca"
                        :value="`Req. marca`"
                        class=" bg-red-300 text-red-700 text-xxs h-4 capitalize"/>
                      <Badge
                        v-if="data.Controlado"
                        value="Controlado"
                        class=" bg-green-300 text-green-700 text-xxs h-4 capitalize"></Badge>
                      <Badge
                        v-if="data.Regulado"
                        value="Regulado"
                        class=" bg-blue-300 text-red-blue text-xxs h-4 capitalize"></Badge>
                      <Badge
                        v-if="data.cadenaFrio"
                        value="Cadena Frio"
                        class="bg-blue-800 text-blue text-xxs h-4"></Badge>
                    </div>
                  </div>
                  <div class="flex">
                    <strong>Nombre: </strong>{{ data.ItemName }}
                  </div>
                </div>
              </template>
            </Column>
            <Column
              field="ItemCode"
              header="Código Cum"
              headerStyle="justify-content: center;"
              style="min-width:8rem"
              bodyStyle="text-align: center">
              <template #body="{data}">
                <div>{{ data.SWW }}</div>
              </template>
            </Column>
            <Column
              field="Quantity"
              header="Cantidades"
              headerStyle="justify-content: center;"
              style="min-width:8rem"
              bodyStyle="text-align: center">
              <template #body="{data}">
                <div class="flex flex-col">
                  <div>
                    <strong>Cant. Pedido: </strong>{{ $h.formatNumber(data.U_PHR_Quantity) }}
                  </div>
                  <div>
                    <strong>Cant. Entrada: </strong>{{ $h.formatNumber(data.Quantity) }}
                  </div>
                </div>
              </template>
            </Column>
            <Column
              field=""
              header="Lotes"
              headerStyle="justify-content: center;"
              style="min-width:5rem"
              bodyStyle="text-align: center">
              <template #body="{data}">
                <div>
                  <Button
                    type="button"
                    class="p-button-xs"
                    icon="pi pi-eye"
                    label="Ver"
                    @click="toggleLotes($event,data)"
                    aria-haspopup="true"
                    aria-controls="overlay_panel_lotes"/>
                  <OverlayPanel
                    ref="opLotes"
                    appendTo="body"
                    :showCloseIcon="true"
                    id="overlay_panel_lotes"
                    style="width: 400px"
                    :breakpoints="{'960px': '75vw'}">
                    <DataTable
                      :value="lotesList"
                      class="p-datatable-sm text-xs"
                      showGridlines
                      dataKey="id"
                      responsiveLayout="scroll"
                    >
                      <Column
                        field="DistNumber"
                        header="Lote"
                        headerStyle="justify-content: center;"
                        style="min-width:5rem"
                        bodyStyle="text-align: center"/>
                      <Column
                        field="ExpDate"
                        header="Fec. Vencimiento"
                        headerStyle="justify-content: center;"
                        style="min-width:5rem"
                        bodyStyle="text-align: center"/>
                      <Column
                        field="CantidadLote"
                        header="Cantidad"
                        headerStyle="justify-content: center;"
                        style="min-width:5rem"
                        bodyStyle="text-align: center">
                        <template #body="{data}">
                          <div>{{ $h.formatNumber(data.CantidadLote) }}</div>
                        </template>
                      </Column>
                      <Column
                        field="Ubicacion"
                        header="Ubicación"
                        headerStyle="justify-content: center;"
                        style="min-width:1rem"
                        bodyStyle="text-align: center"/>
                    </DataTable>
                  </OverlayPanel>
                </div>
              </template>
            </Column>
            <Column
              field="Price"
              header="Precio"
              headerStyle="justify-content: center;"
              style="min-width:8rem"
              bodyStyle="text-align: center">
              <template #body="{data}">
                <div>{{ $h.formatCurrency(data.PriceBefDi) }}</div>
              </template>
            </Column>
            <Column
              field="Price"
              header="Descuento"
              headerStyle="justify-content: center;"
              style="min-width:5rem"
              bodyStyle="text-align: center">
              <template #body="{data}">
                <div>{{ $h.formatNumber(data.DscLinea) + '%' }}</div>
              </template>
            </Column>
            <Column
              field="LineTotal"
              header="Total Linea"
              headerStyle="justify-content: center;"
              style="min-width:8rem"
              bodyStyle="text-align: center">
              <template #body="{data}">
                <div>{{ $h.formatCurrency(data.LineTotal) }}</div>
              </template>
            </Column>
            <Column
              field="WhsCode"
              header="Almacen Ingreso"
              headerStyle="justify-content: center;"
              style="min-width:8rem"
              bodyStyle="text-align: center">
              <template #body="{data}">
                <div>
                  {{ data.WhsCode }} - {{ data.nomBodegaEntrada }}
                </div>
              </template>
            </Column>
            <Column
              field=""
              header="Distribución"
              headerStyle="justify-content: center;"
              style="min-width:5rem"
              bodyStyle="text-align: center">
              <template #body="{data}">
                <div>
                  <Button
                    type="button"
                    class="p-button-xs"
                    icon="pi pi-eye"
                    label="Ver"
                    @click="toggle($event,data)"
                    aria-haspopup="true"
                    aria-controls="overlay_panel"/>
                  <OverlayPanel
                    ref="op"
                    appendTo="body"
                    :showCloseIcon="true"
                    id="overlay_panel"
                    style="width: 400px"
                    :breakpoints="{'960px': '75vw'}">
                    <DataTable
                      :value="distribucionList"
                      class="p-datatable-sm text-xs"
                      showGridlines
                      dataKey="id"
                      responsiveLayout="scroll"
                    >
                      <Column
                        field="cantDistribucion"
                        header="Cantidad"
                        headerStyle="justify-content: center;"
                        style="min-width:1rem"
                        bodyStyle="text-align: center">
                        <template #body="{data}">
                          <div>{{ $h.formatNumber(data.cantDistribucion) }}</div>
                        </template>
                      </Column>
                      <Column
                        field="bodegaOri"
                        header="Bodega"
                        headerStyle="justify-content: center;"
                        style="min-width:1rem"
                        bodyStyle="text-align: center">
                        <template #body="{data}">
                          <div> {{ data.bodegaOri }}</div>
                        </template>
                      </Column>
                    </DataTable>
                  </OverlayPanel>
                </div>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>

    <div class="w-full mt-4">
      <div class="w-full text-xxs grid grid-cols-1 lg:grid-cols-3 gap-2 xl:gap-8">
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 p-1 gap-2 xl:gap-8 lg:col-span-2">
          <div class="flex flex-col">
            <p class="mb-2">
              <strong>Comentarios: </strong>
            </p>
            <Textarea
              class="p-inputtext-xxs"
              disabled
              :autoResize="true"
              v-model="dataEntradaMercancia.Comments"
              rows="2"
              cols="40"
            />
          </div>
          <div class="flex flex-col">
            <p class="mb-2">
              <strong>Entrada de diario: </strong>
            </p>
            <Textarea
              class="p-inputtext-xxs"
              disabled
              :autoResize="true"
              v-model="dataEntradaMercancia.JrnlMemo"
              rows="2"
              cols="40"
            />
          </div>
          <div>
          </div>
        </div>
        <div class="lg:col-span-1">
          <div class="flex justify-between border-b-2 pb-3">
            <span class="text-gray-600">Total sin descuento:</span>
            <span class="">{{ $h.formatCurrency(total) }}</span>
          </div>
          <div class=" flex justify-between my-3">
            <div>
              <span class="text-gray-600 mr-4">% Descuento:</span>
              <InputNumber disabled inputId="integeronly" :min="0" :max="100" inputClass="p-inputtext-xxs text-xxs w-10"
                           v-model="dataEntradaMercancia.DiscDocumento"/>
            </div>
            <div>
              <span class="text-gray-600 mr-4">Total Descuento Documento:</span>
              <span class="">{{ $h.formatCurrency(descuentoDocumento) }}</span>
            </div>
          </div>
          <div class="flex justify-between my-3">
            <span class="text-gray-600">Total Descuento Lineas:</span>
            <span class="">{{ $h.formatCurrency(totalDescuento) }}</span>
          </div>
          <div class="flex justify-between my-3">
            <span class="text-gray-600">Total IVA:</span>
            <span class="">{{ $h.formatCurrency(totalSumIva) }}</span>
          </div>
          <div class="flex justify-between bg-blue-800 text-white rounded-lg p-2 text-sm">
            <span class="font-bold">Total:</span>
            <span class="">{{ $h.formatCurrency(dataEntradaMercancia.DocTotal) }}</span>
          </div>
        </div>
      </div>
    </div>

  </Dialog>
</template>

<script setup>
import { computed, defineExpose, ref } from 'vue'

const displayModal = ref(false)
const dataEntradaMercancia = ref()
const distribucionList = ref([])
const op = ref()

// Funcion para abrir el modal
const openModal = (values) => {
  dataEntradaMercancia.value = values
  displayModal.value = true
}

const total = computed(() => {
  return dataEntradaMercancia.value.detalle.reduce((acc, val) => acc + val.LineTotal, 0)
})
const descuentoDocumento = computed(() => {
  const result = dataEntradaMercancia.value.detalle.reduce((acc, val) => acc + val.LineTotal, 0)
  return result * dataEntradaMercancia.value.DiscDocumento / 100
})
const totalDescuento = computed(() => {
  return dataEntradaMercancia.value.detalle.reduce((total, current) => {
    return total + (current.Quantity * current.PriceBefDi) * (current.DscLinea / 100)
  }, 0)
})
const totalSumIva = computed(() => {
  let result = 0
  result = dataEntradaMercancia.value.detalle.reduce((total, current) => {
    return total + ((current.Quantity * current.PriceBefDi) * current.taxRate / 100)
  }, 0)
  return result
})

//
const toggle = (event, data) => {
  distribucionList.value = data.bodegasOri
  op.value.toggle(event)
}

// Se expone la funcion para abrir el modal, para que pueda ser llamado desde el componente padre
defineExpose({
  openModal
})
</script>

<style scoped>
.header {
  display: grid;
  box-shadow: 3px 3px 3px 4px whitesmoke;
}

.inputsHeader {
  background-color: #eef1f5;
  width: 100%;
  height: 100%;
}

.footerModal {
  display: flex;
  /*justify-content: space-between;*/
  padding: 10px;
}

::v-deep(.pi) {
  font-size: 0.8rem;
}

::v-deep(.p-column-header-content) {
  justify-content: center;
}

::v-deep(.p-card-content) {
  padding: 0rem;
}
</style>
